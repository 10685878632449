<template>
	<div class="detail higtlight" id="blog">
		<div class="title-box" v-if="!text_loading">
			<h1>{{detail.title}}</h1>
			<div class="post-box">
				<div>
					<i class="iconfont lv-icon-kalendar"></i>
					{{detail.created_at}}
				</div>
				<div class="tag-box" v-if="detail.tags && detail.tags.length">
					<i class="iconfont lv-icon-biaoqian6"></i>
					<span v-for="(tagli, index) in detail.tags" :key="index">
						{{tagli}}
					</span>
				</div>
				<div><i class="iconfont lv-icon-huore"></i>{{detail.click}}热度</div>
			</div>
		</div>

		<TextLoading class="article" v-if="text_loading"></TextLoading>

		<section class="article" v-else>
			<div v-html="compiledMarkdown" v-highlight @click="replayImgShow($event)" class="detail-box"></div>


<!-- 富文本中图片放大预览部分 -->
					<div class="previewImg" @click="imgShowHandler" v-show="imgShow" >
					   <img :src="previewImg" alt="">
					</div>
			<!-- 许可 -->
			<div class="posmition">
				<p>文章版权所有：<a href="https://github.com/sweida" target="_black">杨歌</a>，采用 <a
						href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.zh" target="_black">知识共享署名-非商业性使用
						4.0 国际许可协议</a> 进行许可。</p>
				<p>欢迎分享，转载务必保留出处及原文链接 <a :href="href" target="_blank">{{href}}</a></p>
			</div>

			<!-- 点赞 -->
			<div :class="{hasclick:hasclick}" class="giveLike animate03" @click="giveLike">
				<i class="iconfont lv-icon-yijin13-zan"></i>
				<span>{{detail.like}}</span>
			</div>

			<!-- 上一篇和下一篇 -->
			<div class="nextBox">
				<p class="goArticle" @click="goArticle(prevArticle)">
					<Icon type="md-arrow-round-back" />
					<span>{{prevArticle ? prevArticle.title : '无'}}</span>
				</p>
				<p class="goArticle" @click="goArticle(nextArticle)">
					<span>{{nextArticle ? nextArticle.title : '无'}}</span>
					<Icon type="md-arrow-round-forward" />
				</p>
			</div>
		</section>

		<section class="commentbox" id="comment" v-if="user">
			<div class="comment-title">
				<p>评论 <span>「 {{pageModel.total}} 」</span></p>
			</div>

			<div class="input-box">
				<div class="userbox">
					<div class="user-img" v-if="user.id">
						<img :src="user.avatar_url">
						<h4>{{user.name}}</h4>
					</div>
					<div class="user-img" v-else>
						<img :src="`https://avatars.dicebear.com/v2/identicon/id-.svg`" alt="">
						<h4>未登录</h4>
					</div>
				</div>
				<div class="textbox">
					<Input v-model="content" type="textarea" :autosize="{minRows: 4, maxRows: 8}"
						:maxlength="400" placeholder="说点什么。。支持markdown语法，尾部2个空格后回车才会换行，最长400个字" />
					<div class="submit-box">
						<Button type="primary" @click="submitComment">
							<Icon type="ios-create" />
							提交评论
						</Button>
					</div>
				</div>
			</div>
		
			<!-- 评论列表 -->
			<div class="none" v-if="commentList.length<1">
				还没有评论，快来抢沙发。
			</div>
			<div v-else>
				<MyLoading v-if="loading"></MyLoading>
				<div v-else>
					<div class="commentList" v-for="(item, index) in commentList" :key="index">
						<div class="user-ava">
							<img :src="item.user.avatar_url" v-if="item.user">
							<img src="https://avatars.dicebear.com/v2/identicon/id-undefined.svg" v-else>
						</div>
						
						<div class="comment-main">
						  <div class="comment-box animate03">
						    <div class="username"> 
						      <span>
						        <Icon type="md-person" />
						        {{item.user ? item.user.name : '游客'}} 
						        <em v-if="item.user && item.user.is_admin==1">(博主)</em>
						        <span class="created"><Icon type="ios-time-outline" />{{item.create_time}}</span>
						      </span>
						      <!-- <span class="floor">{{getCommentSort(index)}}楼</span> -->
						    </div>
						    <div class="com_detail" v-html="item.content" v-highlight></div>
						    <div class="comment-menu">
						      <!-- 自己的留言显示删除按钮 -->
						      <Poptip
						        confirm
						        placement="left"
						        title="是否删除该留言?"
						        @on-ok="deleteComment(item)">
						        <Icon type="md-trash" v-if="item.user_id == user.id"/>
						      </Poptip>
						      <Tooltip content="回复留言">
						        <i class="iconfont lv-icon-xiaoxi2" @click="handleReply(item, item)"></i>
						      </Tooltip>
						    </div>
							
							
						    <!-- 回复功能 -->
						    <div class="reply-box" v-for="(reply, _index) in item.reply" :key="_index">
						      <p class="reply-header">
						        <span>
						          {{reply.user.name}}
						          <em v-if="reply.user.is_admin==1">(博主)</em>
						          <span class="noml">回复</span>
						          <span>{{reply.topicUser ? reply.topicUser.name : '游客'}}</span>
						          <em v-if="reply.is_admin && reply.is_admin==1">(博主)</em>
						          <span class="created"><Icon type="ios-time-outline" /> {{item.create_time}}</span>
						        </span>
						      </p>
						      <div class="com_detail" v-html="reply.content" v-highlight></div>
						      <div class="comment-menu">
						        <!-- 自己的留言显示删除按钮 -->
						        <Poptip
						          confirm
						          placement="left"
						          title="是否删除该回复?"
						          @on-ok="delCommentReply(reply)">
						          <Icon type="md-trash" v-if="reply.user_id == user.id"/>
						        </Poptip>
						        <Tooltip content="回复留言">
						          <i class="iconfont lv-icon-xiaoxi2" @click="handleReply(item, reply)" ></i>
						        </Tooltip>
						      </div>
							  
						    </div>
						  </div>
						  <!-- 回复评论 -->
						  <div class="reply-text" v-if="reply_show_id == item.id">
						    <Input 
						      v-model="replyContent" 
						      ref="textarea"
						      type="textarea" 
						      :autosize="{minRows: 3, maxRows: 8}" 
						      :maxlength="400"
						      :placeholder="replyTextarea" />
						    <div class="reply-button">
						      <Button class="cancel" @click="cancelReply">
						        取消回复
						      </Button>
						      <Button type="primary" @click="submitReply" >
						        <Icon type="ios-create" />
						        回复留言
						      </Button>
						    </div>
						  </div>
						</div>
						
						
						
						
					</div>
					<NewPage :pageModel="pageModel" @selectList="selectRoleList" v-if="pageModel.total>pageModel.list_rows"></NewPage>
					
					
				</div>
			</div>
		</section>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	import {
		marked
	} from 'marked'
	import '@/assets/style/message.scss'

	export default {
		data() {
			return {
				text_loading: true,
				show: true,
				loading: true,
				checked: true,
				hasclick: false,
				article_id:0,
				detail: [],
				commentList: [],
				prevArticle: {},
				nextArticle: {},
				content:'',
				pageModel: {
					page: 1,
					list_rows: 6,
					total:0
				},
			
				hasMore: true,
				href: '',
				progress: '',
				replyContent: '',
				reply_show_id:0,//显示留言板id
				replyTextarea: '',
				topicUserId :0,//回复用户id
				imgShow:false,
				previewImg:'',//预览图片路径
			}
		},
		computed: {
			...mapGetters([
				'user'
			]),
			compiledMarkdown: function() {
				let content = decodeURIComponent(this.detail.content)
				//参数是markdown    字符串
				return  marked(content, {
					sanitize: false
				})
				
			
			}
		},
		
		created() {
			this.href = window.location.href
            this.article_id = this.$route.params.id
			this.getArticleDetail()
			this.getCommentList()
		},
		watch: {
			$route(to, from) {
				this.href = window.location.href
				this.article_id = this.$route.params.id
				this.getArticleDetail()
				this.getCommentList()
			}
		},
		methods: {
			
			replayImgShow(e){
			  this.previewSrc='';
			  if(e.target.tagName=='IMG'){
				console.log(9999,e.target.currentSrc);
				this.previewImg = e.target.src
				this.imgShow = true
			  }
			},
			
			 imgShowHandler(){
			         this.imgShow = false
			      },
		

			//获取文章详情
			getArticleDetail() {
				let data = {article_id:this.article_id}
				this.$Apis.getArticleDetail(data).then(res => {
					console.log(888,res);
					this.text_loading = false
					this.detail = res.data
					this.prevArticle = res.data.prevArticle
					this.nextArticle = res.data.nextArticle
				}).catch(() => {
					this.$router.replace('/error')
					
				})
			},
			
			
			// 跳转上下一篇文章
			goArticle(article) {
				console.log(7777,article);
				if (article) {
					this.text_loading = true
					this.$router.push(`/blog/${article.id}`)
					this.hasclick = false
				}
			},
			
			// 获取评论
			getCommentList() {
				let param = {
					article_id: this.article_id,
					page:this.pageModel.page,
					list_rows:this.pageModel.list_rows
				}
				
				this.$Apis.getCommentList(param).then(res => {
					this.commentList = res.data.data
					this.pageModel.total = res.data.total
			
					this.commentList.forEach(item => {
						
						item.content = marked(item.content, {
							sanitize: false
						})
					
					})
					
					
					this.loading = false
				}).catch(() => {})
				
				
			},
			
			
			// 提交评论
			submitComment() {
				if(this.content==""){
					return this.$Message.success('请先输入评论内容')
				}
				let user_id = this.user.id?this.user.id:0;
				let data = {
					user_id:user_id,
					article_id: this.article_id,
					content:this.content
				}
				this.$Apis.addComment(data).then(res => {
					this.$Message.success(res.msg)
					this.content=''
					this.getCommentList()
				}).catch(() => {})
			},
			
			
			// 回复留言
			handleReply(item, reply) {
				if (!item.user) {
				  this.$Message.error('不能回复游客！')
				  return
				}
			  this.reply_show_id = item.id
			   this.topicUserId = reply.user.id
			  this.replyTextarea = '回复' + reply.user.name + '：'
			  setTimeout(() => {
			    this.$refs.textarea[0].focus()
			  }, 200)
			},
	
	
	
			//取消留言
			cancelReply()
			{
				 this.reply_show_id = 0
				  this.replyContent = ''
			},
			
			
			// 提交回复
			submitReply() {
				if(this.replyContent==""){
					return this.$Message.success('请先输入回复内容')
				}
				let user_id = this.user.id?this.user.id:0;
			  let params = {
			    content: this.replyContent,
			    comment_id: this.reply_show_id,
			    user_id: user_id,
				topic_user_id: this.topicUserId
				
			  }
			  this.$Apis.replyComment(params).then(res => {
				   this.$Message.success('回复成功')
					this.getCommentList()
					this.cancelReply()
			  }).catch(() =>{})
			},
		
			
			
			// 点赞
			giveLike() {
				let param = {
					article_id: this.detail.id
				}
				if (!this.hasclick) {
					this.$Apis.giveLike(param).then(res => {
						this.detail.like += 1
						this.hasclick = true
					}).catch(() => {})
				}
			},
		
			
			// 评论分页
			selectRoleList(val) {
				console.log(888,val);
				this.pageModel.page = val
				this.getCommentList()
			},
			// 删除自己的留言
			deleteComment(item) {
				this.$Apis.delComment( {comment_id: item.id}).then(res => {
					this.$Message.success('删除成功')
					this.getCommentList()
				}).catch(() => {})
			},
			//删除自己的回复
			delCommentReply(item){
				this.$Apis.delCommentReply( {reply_id: item.id}).then(res => {
					this.$Message.success('删除成功')
					this.getCommentList()
				}).catch(() => {})
			}
		}
	}
</script>


<style  lang="scss">
	.detail-box{
		p{
			 word-break: break-all;
		}
	}
	
	.previewImg{
	  width: 100vw;
	  height: 100vh;
	  z-index: 9999;
	  background:#000000;
	  position: fixed;
	  top:0;
	  left: 0;
	  display: flex;
	  align-items: center;
	  img{
	    width:100vw;
	  }
	}
	.comment-main{
	  flex: 1;
	  }
	.comment-menu{
	  display: flex;
	  justify-content: flex-end;
	  color: #657f86;
	  padding :0 10px 5px 0;
	  i{
	    font-size: 20px;
	    margin-left :10px;
	    cursor: pointer;
		}
	  .lv-icon-xiaoxi2{
	    top: 2px;
	    position: relative;
	}
	}
	
	.reply-text,
	.reply-button{
	  margin-top: 10px;
	  }
	  
	  .cancel{
	    margin-right: 10px;
	  }
	  
	  
	  .reply-box{
	    margin: 0px 10px 7px;
	    border: 1px solid #ecf0f1;
	    .reply-header{
	      display: flex;
	      justify-content: space-between;
	      background: #ecf0f1;
	      margin: 0;
	      padding: 8px 9px;
	      span{
	        color :#f7576c;
	        font-weight: bold;
	        margin: 0 3px;
	  	  }
	      em{
	        color: #009688;
	        font-weight :bold;
	  	  }
	      .created{
	        margin-left: 10px;
	        font-weight: 100;
	        color: #7f8c8d;
	  	  }
	      .noml{
	        color: #7f8c8d;
	  	  }
	    &:hover{
	      box-shadow: 2px 2px 15px #d2e7fd;
	  }
	  }
	  }
	  
	.title-box {
		margin: 20px auto 40px;

		h1 {
			font-size: 3em;
			padding: 0 0 50px;
			color: #22292f;
			line-height: 1.15;
			font-family: monospace;
		}

		.post-box {
			border-top: 1px solid #b8c2cc;
			background: #f8fafc;
			border-bottom: 1px solid #b8c2cc;
			padding: 7px 15px;
			display: flex;
			font-weight: 600;
			color: #606f7b;
			font-family: auto;
			flex-wrap: wrap;
			line-height: 28px;

			>div {
				margin-right: 20px;
			}
		}
	}

	.more {
		margin-top: 10px;
		text-align: center;
		font-size: 14px;
	}

	.none {
		font-size: 14px;
		text-align: center;
	}

	.detail {
		max-width: 840px;
		margin: auto;
		padding: 50px 15px 70px;
		font-size: 16px;
		color: #444;
	}

	.giveLike {
		display: flex;
		margin: 25px auto;
		background: #f7576c;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
		color: #fff;
		font-size: 14px;

		i {
			font-size: 34px;
		}
	}

	.giveLike.hasclick,
	.giveLike.hasclick:hover {
		background: #eee;
		color: #a9aab1;
	}

	.giveLike:hover {
		background: #ff7b8d;
	}

	.posmition {
		background: #ecf0f1;
		font-size: 14px;
		line-height: 22px;
		padding: 15px 20px;
		border-radius: 4px;
		margin: 30px auto;
	}

	.article {
		font-size: 1.3em;
		font-family: Georgia, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
		color: #3d4852;
		padding-bottom: 20px;
	}

	.commentbox {
		max-width: 840px;
		margin: auto;
	}

	.comment-title {
		font-size: 20px;
		margin: 10px 0 15px;
		border-left: 4px solid #f7576c;
		padding-left: 10px;
		line-height: 22px;

		span {
			font-size: 14px;
			margin-top: -6px;
			position: absolute;
		}
	}

	.nextBox {
		border-top: 1px solid #dfe8ea;
		line-height: 26px;
		padding-top: 10px;
		margin-top: 45px;
		display: flex;
		justify-content: space-between;

		.goArticle {
			width: 49%;
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 18px;

			span {
				overflow: hidden;
				margin: 0 5px;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.goArticle:nth-child(2) {
			justify-content: flex-end;
		}

		.goArticle:hover {
			color: #ff7b8d;
		}

		.goArticle:hover span {
			text-decoration: underline;
		}
	}

	.blog-header {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		height: 50px;
		line-height: 50px;
		font-size: 20px;
		font-family: monospace;
		visibility: hidden;
		background: hsla(0, 0%, 100%, .95);
		transition: all .5s cubic-bezier(.19, 1, .22, 1);
		transform: translate3d(0, -120%, 0);
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);

		.title {
			width: 840px;
			margin: auto;
			display: flex;
			align-items: center;

			a {
				display: inherit;
			}

			i {
				color: #a0aec0;
				font-size: 22px;
				margin: 0 15px;
			}
		}

		&.active {
			visibility: visible;
			transition: all .5s cubic-bezier(.22, 1, .27, 1);
			transform: translateZ(0);
		}

	}

	.progressBar {
		height: 4px;
		animation: movingGradient 15s linear infinite;
		background-size: 600% 100%;
		background-image: linear-gradient(120deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, #ee7752, #e73c7e);
		transition: width 0.3s linear 0s;
	}

	@keyframes movingGradient {
		0% {
			background-position: 0 50%
		}



		to {
			background-position: 100% 50%
		}


	}

	@media screen and (min-width: 900px) {
		.banner .bg {
			background: #989898;
		}
	}

	@media screen and (max-width: 750px) {
		.title-box h1 {
			font-size: 2em;
		}

		.article {
			font-size: 1.15em;
		}

		.commentbox {
			border-top: 30px solid #f5f6f7;
		}

		.nextBox {
			flex-wrap: wrap;

			.goArticle {
				margin-bottom: 10px;
				width: 100%;
				cursor: pointer;
			}
		}
	}
</style>
